@import "../variables.scss";
.main-page {
  display: block;
  text-align: center;
  position: relative;
  margin: 0 auto;
  max-width: $width-max;
  color: $main-text;
}

.contacts-h2 {
  font-size: 46px;
  text-align: left;
  margin-bottom: 20px;
  max-width: $width-content;
  margin: 0 auto;
  color: $main-text;
}

.up-btn {
  position: fixed;
  right: 50px;
  bottom: 20px;
  background: $main-color;
  border: none;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 99;
  opacity: 0;
  transform: scale(0.8);
  transition: all 0.4s ease;

  svg {
    color: white;
    font-size: 32px;
    transition: transform 0.3s ease;
  }

  &:hover {
    background: darken($main-color, 10%);
    transform: scale(1);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);

    svg {
      transform: translateY(-3px);
    }
  }

  display: block !important;
  opacity: 1;
  transform: scale(1);
}

.img-block {
  max-width: $width-max;

  margin: 0 auto;
  height: 900px;
  background-image: url(https://avatars.mds.yandex.net/i?id=c66d383a2842aa3f5ec532a91c5fecbe_l-5230359-images-thumbs&ref=rim&n=13&w=1280&h=720);
  background-size: cover;
  background-position: center left;
  background-repeat: no-repeat;
  position: relative;
  transition: all 0.3s ease;

  .img-block-content {
    margin: 0 auto;
    max-width: $width-content;
  }

  .img_text {
    text-align: left;
    padding-top: 70px;
    font-size: 40px;
    width: 560px;
    margin-bottom: 200px;
    color: rgb(245, 245, 245);

    position: relative;
    font-weight: 700;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.8), 0 4px 12px rgba(0, 0, 0, 0.5);
    padding-left: 20px;
    z-index: 2;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    transition: background 0.3s ease;
    z-index: 0;
  }

  &:hover::before {
    background: rgba(0, 0, 0, 0.1);
  }

  & > * {
    position: relative;
    z-index: 1;
  }
}

// блок О нас
.info {
  display: flex;
  margin: 20px auto 0 auto;
  max-width: 1200px;
  padding: 40px 20px;
  background-color: $backgound-color;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  margin-bottom: 80px;

  .info-description {
    text-align: left;
    margin-left: 22px;
    padding: 20px;
    position: relative;
    width: 100%;

    .about-container {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      padding: 20px 0;
      .about-content {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
      }

      .about-text {
        flex: 1;
        min-width: 300px;
      }
      p {
        font-size: 22px;
      }

      .about-map {
        flex: 1;
        min-width: 300px;
      }

      .about-map iframe {
        width: 100%;
        height: 600px;
        border: none;
      }
    }

    h2 {
      font-size: 36px;
      margin-top: 30px;
      margin-bottom: 20px;
      color: $main-color;
      position: relative;
      padding-bottom: 10px;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 111px;
        height: 3px;
        background-color: $a-color;
      }
    }

    &::after {
      content: "";
      position: absolute;
      top: 40px;
      right: 0;
      width: 1px;
      height: calc(100% - 80px);
      background-color: rgba($main-text, 0.1);
    }

    &::before {
      content: "";
      position: absolute;
      bottom: 40px;
      left: -22px;
      width: calc(100% + 22px);
      height: 1px;
      background-color: rgba($main-text, 0.1);
    }
  }

  .info-des-short {
    font-size: 25px;
    margin: 20px 0;
    color: $main-text;
    font-weight: 500;
    line-height: 1.3;
  }

  .info-des-long {
    font-size: 24px;
    padding-bottom: 20px;
    color: lighten($main-text, 15%);
    line-height: 1.6;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    p {
      width: 600px;
      font-size: 24px;
    }
    img {
      width: 600px;
      height: 600px;
      object-fit: cover;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

// БЛОК УСЛУГ
.services-block {
  margin: 0 auto;
  max-width: $width-content;
  margin-bottom: 100px;
  position: relative;

  // h2 {
  //   font-size: 42px;
  //   text-align: left;
  //   margin-bottom: 40px;
  //   color: $main-text;
  //   position: relative;
  //   padding-bottom: 15px;

  //   &::after {
  //     content: "";
  //     position: absolute;
  //     bottom: 0;
  //     left: 0;
  //     // width: 180px;
  //     height: 3px;
  //     background: $main-color;
  //   }
  // }

  .services-background-block {
    // background-image: url(https://avatars.mds.yandex.net/get-altay/6057477/2a00000180c7ec8be59e4d690b814261db60/XXXL);
    background-image: url(https://www.basenybielsko.pl/wp-content/uploads/2014/12/sklep-background.jpg);
    // background-image: url(https://avatars.mds.yandex.net/get-altay/12813969/2a0000018e0845851af875cf8950488541e8/XXXL);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 650px;
    position: relative;
    border-radius: 8px;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(0, 0, 0, 0.3) 100%
      );
    }
  }

  .services-white-block {
    background-color: white;
    position: absolute;
    text-align: left;
    width: 600px;
    padding: 40px;
    right: 50px;
    top: 100px;
    border-radius: 8px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
    border-top: 4px solid $main-color;

    h3 {
      font-size: 28px;
      margin-bottom: 25px;
      color: $main-color;
      position: relative;
      padding-bottom: 10px;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 160px;
        height: 2px;
        background: $a-color;
      }
    }

    p {
      padding: 12px 0 12px 30px;
      font-size: 20px;
      color: $main-text;
      line-height: 1.5;
      position: relative;
      margin-bottom: 8px;

      &::before {
        content: "•";
        position: absolute;
        left: 0;
        color: $a-color;
        font-size: 24px;
        line-height: 1;
      }
    }
  }
}

// БЛОК ПРЕИМУЩЕСТВА
.advantages-block {
  margin: 50px auto;
  max-width: $width-content;
  margin-bottom: 100px;
  h2 {
    font-size: 46px;
    text-align: left;
    margin-bottom: 20px;
  }

  .advantages-box {
    display: flex;
    flex-wrap: wrap;
  }
  .advantages-div {
    flex: 1 1 calc(33.333% - 20px);
    margin: 10px;
    box-sizing: border-box;
  }
}

// Информация
.info-hotel {
  margin: 30px auto;
  margin-bottom: 100px;
  max-width: $width-max;
  // background-color: $main-color;
}

//Блок клиентов
.client-block {
  margin: 30px auto;
  // margin-bottom: 100px;
  max-width: $width-max;
  // object-position: center;
  // object-fit: cover;
  background-image: url(https://hotel-blacksea.com/img/sea_bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 850px;

  .client-white-block {
    background-color: rgba(255, 255, 255, 0.5);
    width: 1400px;
    text-align: left;
  }
}

//ОТзывы
.reviews {
  // background-color: aqua;
  max-width: $width-max;
  // margin: 50px auto 0 auto;
  background-color: $main-color;
  // margin: 0 auto;
  text-align: center;
  position: relative;
  justify-content: center;
  // margin-bottom: 100px;
  // height: 700px;
}
// Карта
// .map-contacts {

// }
//Footer

.footer {
  max-width: $width-max;
  background-color: $main-color;
  margin: 0 auto;
  text-align: center;
  justify-content: center;
  position: relative;

  // height: 250px;
  .TEST {
    position: absolute;
    // position: fixed;
    // width: $width-max;
    // top: -2000px;
    // bottom: 2350px;
    bottom: 1430px;
    width: 100%;
    left: 0;
    z-index: -1;
    // bottom: -4990px;
    // margin: 0 auto;
  }
  div {
    position: relative;
    justify-content: center;
    text-align: center;
  }

  .footer-img {
    width: 200px;
    margin: 15px;
  }
}
