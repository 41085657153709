@import "../variables.scss";

.map-contacts {
  background-color: $main-color;
  max-width: $width-max;
  margin: 0 auto;
  display: flex;
  text-align: center;
  justify-content: center;
  padding: 25px 0 100px 0;
  z-index: 5;
  // margin-bottom: 100px;

  .contacts {
    text-align: left;
    width: 550px;
    background: rgba(255, 255, 255, 0.95);
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08);
    margin-right: 30px;
    border-top: 3px solid $main-color;
    position: relative;

    .h3-a-container {
      // background-color: aqua;
      width: 100%;
      position: relative;
      // display: flex;
      // justify-content: space-between;
      h3 {
        // margin-left: 20px;
        margin-top: 20px;
        font-size: 26px;
        color: $main-color;
        position: relative;
        padding-bottom: 10px;
        // margin-right: 150px;

        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 150px;
          height: 2px;
          background: $a-color;
        }
      }
      a {
        position: absolute;
        right: -155px;
        top: -10px;
        font-size: 18px;
        color: $main-text;
        transition: color 0.2s;

        &:hover {
          color: $a-color;
        }
      }
    }

    p {
      font-size: 20px;
      padding: 10px 15px 10px 0;
      color: $main-text;
      line-height: 1.5;
    }

    .route-btn {
      // background-color: black;
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid $main-color;
      font-size: 18px;
      margin-right: 160px;
      cursor: pointer;
      padding: 8px 20px 10px 20px;
      border-radius: 30px;
      color: $main-color;
      background: white;
      transition: all 0.3s ease;

      &:hover {
        background: $main-color;
        color: white;

        .arrow-btn {
          transform: translateX(10px);
          color: white;
        }
      }
    }
  }
}

.weather-clock-block {
  justify-content: left;
  align-items: left;
  display: flex;

  position: absolute;
  bottom: 20px;
}
.weather-clock-block > * + * {
  margin-left: 30px;
}
.card {
  opacity: 1;
  transform: translateY(40px) scale(0.8);
  animation: fadeIn 1.2s ease-in forwards;
  animation-timeline: view();
  animation-range: entry 0% cover 30%;
}

@keyframes fadeIn {
  from {
    opacity: 1;
    transform: translateY(40px) scale(0.8);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}
