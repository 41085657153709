@import "./variables.scss";
* {
  margin: 0;
  padding: 0;
  // color: $main-color;

  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
body {
  color: $main-color;
  background-color: $backgound-color;
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
  color: inherit;
}

// body {
// }
