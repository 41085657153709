@import "../variables.scss";
.carousel {
  overflow: hidden;
  width: 100%;
  position: relative;
  margin-bottom: 100px;
  background-color: $main-color;
}

// img Swiper
.room-images-swiper {
  width: 720px;
  height: 500px;
  border-radius: 4px;
}

.carousel-box {
  // height: 500px;
  // width: 500px;
}
.carousel-container {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%;

  .back-gr {
    display: flex;
    max-width: 1300px;
    margin: 0 auto;

    padding: 50px;
    background: rgba(255, 255, 255, 0.95);
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  }
  .carousel-item {
    min-width: 100%;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    // flex-direction: column;
    justify-content: center;
    // align-items: center;
    padding: 30px;
    position: relative;
    flex-shrink: 0;
  }

  .carousel-item-description {
    /* Сохраняем все исходные параметры */
    position: relative;
    text-align: left;
    max-width: 500px;
    margin-top: 20px;
    padding: 15px;

    .h2-carousel {
      font-size: 26px;
      margin-bottom: 10px;

      color: $main-text;
      font-weight: 600;
      line-height: 1.2;
    }

    .sd-carousel {
      font-size: 20px;
      margin-bottom: 50px;

      color: lighten($main-text, 15%);
      line-height: 1.4;
    }

    .size-carousel {
      font-size: 18px;

      display: flex;
      align-items: center;
      color: $main-text;

      svg {
        margin-right: 8px;
        color: $a-color;
      }
    }
    .more-btn-carousel {
      position: absolute;
      bottom: 90px;

      font-size: 18px;

      border: none;
      background: none;
      color: $main-color;
      padding: 0;
      cursor: pointer;
      font-weight: 500;
      transition: all 0.2s ease;

      &:hover {
        color: darken($main-color, 10%);
      }

      &:hover::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -2px;
        width: 100%;
        height: 2px;
        background-color: $main-color;
        animation: underline 0.3s ease;
      }
    }
    @keyframes underline {
      from {
        width: 0;
      }
      to {
        width: 100%;
      }
    }

    // p {
    //   text-align: left;
    //   justify-content: left;
    // }
    .carousel-btn-containter {
      position: absolute;
      width: 100%;
      display: flex;
      bottom: 16px;
      text-align: center;

      justify-content: space-between;
      // background-color: #333;

      .carousel-btn-book {
        display: flex;
        align-items: center;
        background-color: white;
        border: 2px solid $main-color;
        color: $main-color;
        font-size: 18px;
        font-weight: 500;
        padding: 8px 20px;
        border-radius: 15px;
        cursor: pointer;
        transition: all 0.3s ease;
        // margin-left: 20px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

        .arrow-btn {
          font-size: 22px;
          margin-right: 8px;
          transition: transform 0.3s ease;
        }

        &:hover {
          background-color: $main-color;
          color: white;

          .arrow-btn {
            transform: translateX(5px);
          }
        }
      }

      .carousel-btn-group {
        display: flex;
        margin-right: 40px;
        margin-top: 5px;
      }

      .carousel-btn {
        background-color: white;
        border: 2px solid $main-color;
        color: $main-color;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.3s ease;
        margin: 0 5px;

        &:hover {
          background-color: $main-color;
          color: white;
        }
      }
    }
  }

  .carousel-img {
    width: 590px;
    height: 440px;
    // height: 5px;
    padding: 15px;
    object-position: center;
    object-fit: cover;
  }
}

/* Модальное окно - полные стили */
.modal-overlay-carousel {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  overflow-y: auto;
}

.modal-content-carousel {
  background-color: white;
  border-radius: 8px;
  max-width: 1200px;
  width: 100%;
  max-height: 90vh;
  overflow-y: auto;
  padding: 20px;
  position: relative;
}

.close-modal-btn-carousel {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  z-index: 10;
}

.modal-main-content {
  display: flex;
  gap: 30px;
  margin-bottom: 30px;
}

.modal-image-section {
  flex: 1;
  min-width: 0; // важно для корректной работы

  .modal-room-images-swiper {
    height: 400px;
    border-radius: 8px;
    overflow: hidden;

    // Стили для свайпера
    .swiper-slide {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.modal-text-section {
  flex: 1;
  display: flex;
  flex-direction: column;

  .modal-title {
    font-size: 24px;
    margin-bottom: 15px;
    color: #333;
  }

  .modal-description {
    flex-grow: 1;

    p {
      margin-bottom: 15px;
      line-height: 1.5;
    }

    .room-size {
      font-weight: bold;
    }
  }
}

.modal-features-container {
  // background-color: antiquewhite;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;

  .feature-category {
    // background-color: aqua;
    .category-title {
      font-size: 18px;
      // margin-top: -20px;
      margin-bottom: 10px;
      color: #555;
      text-align: left;
      padding-left: 14px;
    }

    .feature-items {
      display: flex;
      flex-direction: column;
      text-align: left;
      gap: 8px;

      .feature-item {
        padding-left: 15px;
        position: relative;
        line-height: 1.4;

        &::before {
          content: "•";
          position: absolute;
          left: 0;
          color: #777;
        }
      }
    }
  }
  .feature-category:not(:last-child) {
    border-right: 1px solid black;
    padding-right: 10px;
    // padding-top: 4px;
  }
}

// Адаптивные стили
@media (max-width: 900px) {
  .modal-main-content {
    flex-direction: column;
  }

  .modal-image-section {
    .modal-room-images-swiper {
      height: 300px;
    }
  }
}

@media (max-width: 600px) {
  .modal-features-container {
    grid-template-columns: 1fr;
  }
}
