@import "../variables.scss";

.gallery-container {
  width: 100%;
  height: 800px;
  overflow: hidden;
  position: relative;
  margin: 2rem 0;
  margin-bottom: 100px;
}

.h2-gallery {
  text-align: left;
  max-width: $width-content;
  margin: 0 auto;
  font-size: 2.5rem;
  // color: $main-color;
  padding-top: 45px;
  margin-bottom: 1.5rem;
  padding-top: 1rem;
  font-family: "Playfair Display", serif;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.gallery-img-block {
  display: flex;
  height: calc(100% - 100px);
  padding: 0 2rem;
  gap: 2rem;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  align-items: center;

  // Стилизация скроллбара
  &::-webkit-scrollbar {
    height: 12px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: $main-color;
    border-radius: 10px;
    border: 2px solid transparent;
    background-clip: content-box;

    &:hover {
      background: darken(
        $main-color,
        10%
      ); // Автоматически затемняем основной цвет
    }
  }

  img {
    scroll-snap-align: center;
    height: 90%;
    width: auto;
    max-width: 1200px;
    object-fit: contain;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
      transform: scale(1.03);
      box-shadow: 0 8px 25px rgba(0, 0, 0, 0.25);
    }
  }
}

// Адаптация для мобильных устройств
@media (max-width: 768px) {
  .gallery-container {
    height: 500px;
  }

  .h2-gallery {
    font-size: 2rem;
  }

  .gallery-img-block {
    gap: 1.5rem;
    padding: 0 1.5rem;

    img {
      height: 85%;
    }
  }
}
