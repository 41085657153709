@import "../variables.scss";

.info-container {
  max-width: 1040px;
  // height: 400px;
  margin: 20px auto;
  display: flex;
  justify-content: space-between;
  gap: 30px;
  background: rgba(255, 255, 255, 0.95);
  padding: 20px 30px;

  // border-radius: 50px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08);
  // border-top: 30px solid $main-color;
  // border-bottom: 30px solid $main-color;
}

.info-desc-container {
  text-align: left;
  // padding: 10px;

  flex: 1;

  h3 {
    font-size: 24px;
    margin: 15px 0 10px;
    color: $main-color;
    position: relative;
    padding-bottom: 8px;

    // &::after {
    //   content: "";
    //   position: absolute;
    //   bottom: 0;
    //   left: 0;
    //   width: 50px;
    //   height: 2px;
    //   background: $a-color;
    // }
  }

  a {
    font-size: 18px;
    color: $main-text;
    text-decoration: none;
    transition: color 0.2s;
    position: relative;

    &:hover {
      color: $a-color;
      // text-decoration: underline;
    }
    &::after {
      content: "";
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 250px;
      height: 2px;
      background: $a-color;
    }
  }
}

.info-btn-container {
  width: 270px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.mini-reviews {
  margin: 25px 0;
}

.modal-btn {
  margin: 8px 0;
  border: 2px solid $main-color;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  padding: 12px 20px;
  color: white;
  background-color: $main-color;
  transition: all 0.3s ease;
  text-align: center;
  font-weight: 500;

  &:hover {
    color: $main-color;
    background-color: white;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba($main-color, 0.2);
  }

  &:first-child {
    margin-top: 20px;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  position: relative;
  max-width: 800px;
  width: 90%;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);

  img {
    max-width: 100%;
    max-height: 80vh;
    border-radius: 5px;
    border: 1px solid #eee;
  }

  button {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    background: none;
    border: none;
    font-size: 24px;
    color: $main-text;
    transition: color 0.2s;

    &:hover {
      color: $a-color;
    }
  }
}

.modal-div-container {
  width: 100%;
  color: $main-text;
  text-align: left;

  h3 {
    font-size: 24px;
    color: $main-color;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba($main-color, 0.2);
  }

  p {
    font-size: 18px;
    margin: 15px 0;
    padding-left: 25px;
    position: relative;
    line-height: 1.5;

    &::before {
      content: "•";
      position: absolute;
      left: 0;
      color: $a-color;
      font-size: 24px;
      line-height: 1;
    }
  }
}
