@import "../variables.scss";
.header-nav {
  max-width: $width-max;
  margin: 0 auto;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  background-color: white;
  position: relative;
  z-index: 100;

  // .header {
  //   background-color: $main-color;
  //   color: white;

  //   .header-content {
  //     display: flex;
  //     margin: 0 auto;
  //     height: 50px;
  //     font-size: 16px;
  //     justify-content: space-between;
  //     align-items: center;
  //     width: $width-content;
  //     padding: 0 20px;

  //     .message-btn {
  //       font-size: 16px;
  //       padding: 8px 16px;
  //       border-radius: 25px;
  //       border: 1px solid white;
  //       background-color: transparent;
  //       color: white;
  //       cursor: pointer;
  //       transition: all 0.3s ease;

  //       &:hover {
  //         background-color: white;
  //         color: $main-color;
  //         transform: translateY(-2px);
  //         box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  //       }
  //     }

  //     .header_text {
  //       font-weight: 500;
  //     }
  //   }
  // }

  .header_nav-ad-address {
    display: flex;
    padding: 20px 0;
    margin: 0 auto;
    max-width: $width-content;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .nav-address {
      display: flex;
      align-items: center;
      gap: 20px;

      .logo {
        width: 120px;
        height: auto;
        transition: transform 0.3s ease;

        &:hover {
          transform: scale(1.05);
        }
      }

      .div-address {
        h2 {
          margin: 0;
          font-size: 28px;
          text-align: left;
          color: $main-text;
          font-weight: 700;
        }

        p {
          margin: 5px 0;
          font-size: 14px;
          color: lighten($main-text, 20%);
        }

        .contact-links {
          display: flex;
          gap: 15px;
          margin-top: 8px;

          a {
            color: $main-text;
            text-decoration: none;
            font-size: 14px;
            transition: color 0.3s ease;

            &:hover {
              color: $a-color;
              text-decoration: underline;
            }
          }
        }
      }
    }
    // .div_nav-callback {
    //   background-color: aqua;
    // }
    .nav-callback-btn {
      background-color: $main-color;
      color: white;
      font-size: 16px;
      padding: 10px 20px;
      border-radius: 25px;
      border: none;
      cursor: pointer;
      transition: all 0.3s ease;
      height: fit-content;

      &:hover {
        background-color: darken($main-color, 10%);
        transform: translateY(-2px);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      }
    }
    .tel-what {
      // background-color: rgb(99, 245, 245);
      text-align: right;
      font-size: 50px;
      .telegram {
        color: #3390ec;
      }
      .whatsapp {
        color: #00d757;
        margin: 0 10px 0 10px;
      }
    }
  }

  .nav-bar {
    background-color: white;
    padding: 0 20px;

    .header_nav-list {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      padding: 0;
      list-style: none;
      gap: 10px;
      max-width: $width-content;

      .header_nav-item {
        padding: 15px 20px;
        font-size: 18px;
        cursor: pointer;
        color: $main-text;
        position: relative;
        transition: all 0.3s ease;
        font-weight: 500;

        &:hover {
          color: $main-color;

          &::after {
            width: 100%;
          }
        }

        &::after {
          content: "";
          position: absolute;
          bottom: 10px;
          left: 0;
          width: 0;
          height: 2px;
          background-color: $main-color;
          transition: width 0.3s ease;
        }
      }
    }
  }
}

// Адаптивные стили
@media (max-width: 1024px) {
  .header-nav {
    .header_nav-ad-address {
      flex-direction: column;
      gap: 20px;
      padding: 20px;

      .nav-address {
        flex-direction: column;
        text-align: center;

        .div-address {
          h2,
          p,
          .contact-links {
            text-align: center;
            margin-left: 0;
            padding-left: 0;
          }
        }
      }
    }

    .nav-bar {
      .header_nav-list {
        flex-wrap: wrap;
        gap: 5px;

        .header_nav-item {
          padding: 10px 15px;
          font-size: 16px;
        }
      }
    }
  }
}
