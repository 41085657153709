@import "../variables.scss";

.header-nav-scroll {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  z-index: 99;
  animation: slideIn 0.3s ease-out;
  padding: 10px 0;
  transform: translateY(-100%);
  opacity: 0;
  transition: transform 0.3s ease, opacity 0.3s ease;

  &.visible {
    // Добавьте класс "visible" при рендере {showStickyNav && <NavBarScroll className="visible" />}
    transform: translateY(0);
    opacity: 1;
  }

  .nav-bar-scroll {
    max-width: $width-content;
    margin: 0 auto;

    .header_nav-list-scroll {
      display: flex;
      justify-content: center;
      margin: 0;
      padding: 0;
      list-style: none;
      gap: 25px;

      .header_nav-item-scroll {
        padding: 5px 15px;
        font-size: 18px;
        cursor: pointer;
        color: $main-text;
        position: relative;
        transition: color 0.3s ease;
        font-weight: 500;

        &:hover {
          color: $main-color;

          &::after {
            width: 100%;
          }
        }

        &::after {
          content: "";
          position: absolute;
          bottom: 5px;
          left: 0;
          width: 0;
          height: 2px;
          background-color: $main-color;
          transition: width 0.3s ease;
        }
      }
    }
  }
}

// Адаптив для мобильных
@media (max-width: 768px) {
  .header-nav-scroll {
    padding: 8px 0;

    .nav-bar-scroll {
      .header_nav-list-scroll {
        gap: 10px;

        .header_nav-item-scroll {
          padding: 8px 10px;
          font-size: 16px;
        }
      }
    }
  }
}
