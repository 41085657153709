// .clock-container {
//   background-color: #617bff;
//   color: white;
//   padding: 20px;
//   border-radius: 8px;
//   text-align: center;
//   width: 300px;
//   margin: 0 auto;
// }

// .clock-header {
//   font-size: 16px;
//   margin-bottom: 10px;
//   font-weight: bold;
// }

// .clock-time {
//   font-size: 48px;
//   font-weight: bold;
//   margin: 10px 0;
// }

// .clock-date {
//   font-size: 16px;
// }
// ................
.bs-usefull__info {
  display: flex;
  padding: 5px;
  text-align: left;
  justify-content: left;
}
.bs-usefull__info-time {
  font-size: 22px;
  padding: 10px;
}
.irframe {
  overflow: hidden;
  border: 0;
  margin: 0;
  padding: 0;
  width: 120px;
  height: 40px;
}
// .img-clock {
//   height: 250px;
// }
